import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import VueHtml2pdf from 'vue-html2pdf';
import MasterService from "@/core/services/master.service";
// import KTUtil from "@/assets/js/components/util";

export default {
  components: {
    // Dropdown1
    VueHtml2pdf
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ]),
    getLogo(){
      return process.env.BASE_URL+'media/logos/logo.jpg';
    }
  },

  data() {
    return {
      title: "Request Customer",
      
      // :core
      dataUser: {},
      searchMain: {},
      role_kode : null,
      user_id : null,
      user_id_induk :null,
      // :end core

      // :last 10 transaction
      tContentDetail: {},
      tContents: [],
      tContentsTotal: 1,
      tContentsHeader: [
            
      ],

      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      // :end last 10 transaction

      // :additional
      param_id: null,
      flag : null,
      loadingGenerate: false,
      dialogDetail : false,
      
      dataStatuspurch: null,
      form: {},
      formValid: false,
      commonValidation: [v => !!v || 'This field is required'],
      // :end additional
    };
  },

  mounted() {
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Transactions", route: "/" },
      { title: "Request Customer", route: "/transactions/surat_jalan/list" },
      { title: "Detail" }
    ]),

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    this.searchMain.gudang_id = Number(this.currentUser.gudang_id);
    this.role_kode = this.currentUser.role_kode;
    this.user_id = Number(this.currentUser.id);
    this.user_id_induk = Number(this.currentUser.atasan_id);
    

    this.param_id = this.$route.params.id;

    let packobject = this.$route.query;
    this.flag = packobject.flag;
    
    if(this.param_id){
      this.loadMainContent();
    }

    // this.loadStatuspurch();
  },

  watch: {
    //
  },

  methods: {
    generateReport() {
      this.loadingGenerate = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf()
      }, 50);
      setTimeout(() => {
        this.loadingGenerate = false;
      }, 2000);
    },

    showFormDialogDetail(){
      setTimeout(() => {
        this.dialogDetail = true;
      }, 50);
    },
    // :loader
    loadMainContent(){
      this.tContentsLoading = true;

      var query = { 
        main: this.searchMain,
        data: { id : this.param_id }
      }

      // ApiService.setHeader();
      ApiService.post("surat_jalan/detail", query, 1).then(({ data }) => {
        this.tContentsLoading = false;
        this.tContents = data.detail;
      }).catch(({ response }) => {
        this.tContentsLoading = false;
        this.$router.push({ path: "/awc/transactions/surat_jalan/list" });
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },

    loadStatuspurch(){
      MasterService.loadStatuspurch().then((data) =>{
        this.dataStatuspurch = data;
      })
    },

    // :end loader
  },
  
};